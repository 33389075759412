import styled, { ThemeContext } from 'styled-components';
import { useContext } from 'react';
import { logEvent } from '../../../../new-lib/analytics';
import ServiceCard from './ServiceCard';

const ServicesContainer = () => {
  const theme = useContext(ThemeContext);

  return (
    <Container itemScope itemType="http://schema.org/Service">
      <ServiceCard
        backgroundColor="#FFEFE6"
        cardTitle="nawy_now_card_title"
        cardDescription="nawy_now_card_description"
        titleColor="#da3d00"
        img="/assets/icons/common/nawy-now-logo.svg"
        linkTo="/nawy-now"
        onChange={() => {
          logEvent.click('nawynow_click', 'NawyNowCard', 'other');
        }}
        dataTest="nawy-now-btn"
      />
      <ServiceCard
        backgroundColor="#F7F7F7"
        cardTitle="sell_home_card_title"
        cardDescription="sell_home_card_description"
        titleColor={theme.light.colors.primaryBlue}
        img="/assets/icons/common/nawy-sell-logo.svg"
        linkTo="/sell-my-property"
        onChange={() => {
          logEvent.click('sell_click', 'SellCard', 'other');
        }}
         dataTest="sell-btn"
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 16px;
  height: auto;

  @media screen and (max-width: ${props =>
      props.theme.breakPoints.tablet.max}) {
    flex-direction: column;
    gap: 32px;
    height: auto;
  }
`;
export default ServicesContainer;
