import styled from 'styled-components';
import { getImagePath } from '../../helpers/common';
import ImageWrapper from '../ImageWrapper';
import { useSelector } from 'react-redux';

interface LogoProps {
  logoPath: string;
  handleClick?: () => void;
}
const Logo = ({ logoPath, handleClick }: LogoProps) => {
  const isMobile = useSelector((state: any) => state.app.isMobile);

  return (
    <LogoContainer className="logo-container" itemProp="logo">
      <div className="logo-wrapper" onClick={handleClick}>
        <div className="img-wrapper">
          <ImageWrapper
            src={getImagePath(logoPath)}
            alt="logo"
            fill
            priority
            withSkeleton
            objectFit="cover"
            imageSize={isMobile ? 'medium' : 'high'}
            quality={50}
            sizes="50vw"
          />
        </div>
      </div>
    </LogoContainer>
  );
};

const LogoContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f0f04b;
  cursor: pointer;

  .logo-wrapper {
    width: 90%;
    height: 90%;
    background: #f0f0f04b;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      @media (hover: hover) and (pointer: fine) {
        /* background: linear-gradient(
          270deg,
          rgba(255, 94, 0, 1) 0%,
          rgba(0, 189, 207, 1) 100%
        ); */
        /* background: #e6e6e6; */
      }
    }
    
    .img-wrapper {
      width: 80%;
      height: 80%;
      overflow: hidden;
      border-radius: 50%;
      position: relative;
    }
  }
`;
export default Logo;
