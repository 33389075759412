import { GetServerSidePropsContext } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import {
  getTopAreas,
  getPopularCompounds,
  getFooterLinksCached,
  getLaunchesSearch,
  getSearchLocations,
  getPathways,
  getFilterOptionsCached,
  getConfigVariablesCached,
  getAreas,
  getDevelopers
} from '../new-lib/apis/index';
import HomePageContainer from '../features/home';
import { getSelectorsByUserAgent } from 'react-device-detect';
import { setExperimentVariant, setIsMobile } from '../redux/actions/appActions';
import { mapOptionsToNewForm } from '../helpers/search-options/common';
import { wrapper } from '../redux/store';
import { staticFilterOptions } from '../new-lib/constants/filterOptions';

const Home = (props: any) => {
  return <HomePageContainer {...props} />;
};

export const getServerSideProps = wrapper.getServerSideProps(
  store =>
    async ({ req, locale, query }: GetServerSidePropsContext) => {
      try {
        const [
          pathways,
          popularCompounds,
          topAreas,
          launches,
          configVariables,
          filterOptions,
          searchResults,
          areas,
          developers
        ] = await Promise.all([
          getPathways('home_page', locale),
          getPopularCompounds(locale),
          getTopAreas(locale),
          getLaunchesSearch(
            locale,
            { start: 0, page_size: 5 },
            'website_summary'
          ),
          getConfigVariablesCached(),
          getFilterOptionsCached(locale),
          getSearchLocations('', locale),
          getAreas(false, locale),
          getDevelopers(false, locale)
        ]);

        const isMobile = getSelectorsByUserAgent(
          req.headers['user-agent']!
        ).isMobile;

        store.dispatch(setIsMobile(isMobile));
        store.dispatch(setExperimentVariant(req.cookies['_ab'] ?? null));
        return {
          props: {
            ...(await serverSideTranslations(locale as string, [
              'home',
              'common',
              'contact',
              'search',
              'launches',
              'compare',
              'sahel'
            ])),
            layout: {
              url: process.env.NEXT_PUBLIC_HOST,
              footerLinks: await getFooterLinksCached(locale),
              searchResults,
              path: '/'
            },
            filterOptions: filterOptions
              ? JSON.stringify(filterOptions)
              : JSON.stringify(mapOptionsToNewForm(staticFilterOptions)),
            configVariables,
            topAreas,
            areas: areas,
            developers: developers,
            pathways: pathways,
            popularCompounds: popularCompounds,
            launches: launches.launches,
            query
          }
        };
      } catch (err) {
        return {
          notFound: true
        };
      }
    }
);

export default Home;
