import React from 'react';
import { useSelector } from 'react-redux';

// Components
import MobileLandingView from './MobileLandingView';
import DesktopLanding from './DesktopLanding';

const Landing: React.FC<any> = () => {
  const isMobile = useSelector((state: any) => state.app.isMobile);

  return !isMobile ? <DesktopLanding /> : <MobileLandingView />;
};

export default Landing;
