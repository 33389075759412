import { ChangeEvent, useCallback, useContext, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import SearchBarPresentation from './SearchBarPresentation';
import {
  SearchContext,
  SearchContextInterface
} from '../../../search-new/context/SearchContext';
import { SearchFiltersTypes } from '../../../../reducers/search-filters/reducer';
import { logEvent } from '../../../../new-lib/analytics';

const SearchBarContainer = () => {
  const { filterOptions, searchFiltersState, searchFiltersDispatch } =
    useContext(SearchContext) as SearchContextInterface;
  const { t } = useTranslation('search');
  const router = useRouter();
  const [activeTab, setActiveTab] = useState('compound');
  const getPropertyTypes = useCallback(() => {
    let propertyTypes: any = [];
    filterOptions.property_types.forEach((item: any) => {
      propertyTypes.push({
        value: item.value,
        name: item.options.displayedValue
      });
    });
    return propertyTypes;
  }, []);
  const getBedroomOptions = useCallback(() => {
    let options = [];
    for (let index = 1; index < 5; index++) {
      options.push({ value: index, name: `${index} ${t('bedrooms')}` });
    }
    options.push({ value: '5+', name: `5+ ${t('bedrooms')}` });
    return options;
  }, []);
  const getBathroomOptions = useCallback(() => {
    let options = [];
    for (let index = 1; index < 5; index++) {
      options.push({ value: index, name: `${index} ${t('bathrooms')}` });
    }
    options.push({ value: '5+', name: `5+ ${t('bathrooms')}` });
    return options;
  }, []);

  const getCombinedOptions = useCallback(() => {
    const bedroomOptions = getBedroomOptions();
    const bathroomOptions = getBathroomOptions();
    const combinedOptions = [
      ...bedroomOptions.map(option => ({
        ...option
      })),
      ...bathroomOptions.map(option => ({
        ...option
      }))
    ];

    return combinedOptions;
  }, [getBedroomOptions, getBathroomOptions]);

  const initialFilterOptions = {
    property_types: {
      name: 'property_types',
      options: getPropertyTypes(),
      selectedValues: []
    },
    bedrooms: {
      name: 'bedrooms',
      options: getBedroomOptions(),
      selectedValues: []
    },
    bathrooms: {
      name: 'bathrooms',
      options: getBathroomOptions(),
      selectedValues: []
    },
    beds_baths: {
      name: 'beds_baths',
      options: getCombinedOptions(),
      selectedValues: []
    },
    price: {
      name: 'price_range',
      selectedValues: []
    }
  };
  const [filters, setFilters] = useState<any>(initialFilterOptions);

  const handlePriceRangeSelect = (minPrice: number, maxPrice: number) => {
    const updatedFilter = {
      ...filters['price'],
      selectedValues: [{ min: minPrice, max: maxPrice }]
    };

    setFilters({
      ...filters,
      price: updatedFilter
    });
  };

  const handleSelect = (
    e: ChangeEvent<HTMLInputElement>,
    filterType: string
  ) => {
    const { value, name, checked } = e.target;
    const updateSelectedValues = (selectedValues: any[]) => {
      if (checked) {
        if (!selectedValues.some(item => item.value === value)) {
          return [...selectedValues, { value, name }];
        }
      } else {
        return selectedValues.filter(item => item.value !== value);
      }
      return selectedValues;
    };
    const updatedSelectedValues = updateSelectedValues(
      filters[filterType].selectedValues
    );

    const updatedFilters = {
      ...filters,
      [filterType]: {
        ...filters[filterType],
        selectedValues: updatedSelectedValues
      }
    };

    // If the filter is 'bedrooms' or 'bathrooms', update 'beds_baths' as well
    if (filterType === 'bedrooms' || filterType === 'bathrooms') {
      const combinedSelected = [
        ...updatedFilters['bedrooms'].selectedValues,
        ...updatedFilters['bathrooms'].selectedValues
      ];

      updatedFilters.beds_baths = {
        ...filters['beds_baths'],
        selectedValues: combinedSelected
      };
    }
    setFilters(updatedFilters);
  };

  const mapSelectedToQuery = () => {
    let queryArray = [];

    queryArray.push(`category=${activeTab}`);

    if (filters['property_types'].selectedValues.length > 0) {
      const propertyTypes = `${SearchFiltersTypes.PROPERTY_TYPES}=${filters[
        'property_types'
      ].selectedValues
        .map((item: any) => item.value)
        .toString()}`;
      queryArray.push(propertyTypes);
    }
    if (filters['bedrooms'].selectedValues.length > 0) {
      const bedrooms = `${SearchFiltersTypes.BEDROOMS}=${filters[
        'bedrooms'
      ].selectedValues
        .map((item: any) => item.value)
        .toString()}`;
      queryArray.push(bedrooms);
    }
    if (filters['bathrooms'].selectedValues.length > 0) {
      const bathrooms = `${SearchFiltersTypes.BATHROOMS}=${filters[
        'bathrooms'
      ].selectedValues
        .map((item: any) => item.value)
        .toString()}`;
      queryArray.push(bathrooms);
    }
    if (filters['price'].selectedValues.length > 0) {
      const priceValues = filters['price'].selectedValues[0];

      const minValue = priceValues.min;
      const maxValue = priceValues.max;
      const price = `${SearchFiltersTypes.MIN_PRICE}=${minValue}&${SearchFiltersTypes.MAX_PRICE}=${maxValue}`;
      queryArray.push(price);
    }

    if (searchFiltersState.searchFilters.areas.length > 0) {
      const areas = `${
        SearchFiltersTypes.AREAS
      }=${searchFiltersState.searchFilters.areas.toString()}`;
      queryArray.push(areas);
    }

    if (searchFiltersState.searchFilters.developers.length > 0) {
      const developers = `${
        SearchFiltersTypes.DEVELOPERS
      }=${searchFiltersState.searchFilters.developers.toString()}`;
      queryArray.push(developers);
    }

    return queryArray.join('&');
  };

  const handleSearch = () => {
    router.push({ pathname: '/search', query: mapSelectedToQuery() });
    logEvent.search('search_click', 'SearchBar', 'search', searchFiltersState);
  };

  return (
    <SearchBarPresentation
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      filters={filters}
      handleSelect={handleSelect}
      handleSearch={handleSearch}
      handlePriceRangeSelect={handlePriceRangeSelect}
      selectedFilters={mapSelectedToQuery()}
    />
  );
};

export default SearchBarContainer;
