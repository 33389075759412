import styled from 'styled-components';
import router, { useRouter } from 'next/router';
import { logEvent } from '../../../../new-lib/analytics';
import ImageWrapper from '../../../../new-components/ImageWrapper';

const OffersBannerDesktop = (): JSX.Element => {
  const { locale } = useRouter();

  const bannerClickHandler = () => {
    router.push('/cityscape');
    logEvent.click('click_offers_banner', 'desktop_offers_banner', 'offers');
  };

  return (
    <Container
      onClick={bannerClickHandler}
      onLoad={() => router.prefetch('/cityscape')}
      itemScope
      itemType="http://schema.org/OfferCatalog"
    >
      <ImageWrapper
        src={`/assets/images/offers/offers-desktop-${locale}.svg`}
        alt="Offers content"
        width={414}
        height={114}
        priority
      />
      <ImageWrapper
        src="/assets/images/offers/percent.svg"
        alt="percent"
        width={72}
        height={70}
        priority
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  height: max-content;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  background: url('/assets/images/banners/offers-bg-desktop.webp') no-repeat
    center center;
  background-size: cover;
`;
export default OffersBannerDesktop;
