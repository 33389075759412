import { useSelector } from 'react-redux';
import OffersBannerDesktop from './OffersBannerDesktop';
import OffersBannerMobile from './OffersBannerMobile';

export interface OffersBannerProps {
  hasEnded?: boolean;
}

const OffersBannerContainer = () => {
  const isMobile = useSelector((state: any) => state.app.isMobile);

  return isMobile ? <OffersBannerMobile /> : <OffersBannerDesktop />;
};

export default OffersBannerContainer;
