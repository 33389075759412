import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import WhatsappBuilder from '../helpers/whatsapp/whatsapp-builder';
import { useRouter } from 'next/router';
import { CTATypes } from '../new-lib/constants/common';

type QueryProps = {
  query_params: { whatsapp_redirect: string };
};

const useQueryRedirect = ({ query_params }: QueryProps) => {
  const { asPath, locale } = useRouter();
  const referer = useSelector((state: any) => state.app.referer);
  const userId = useSelector((state: any) => state.app.userId);
  const eventId = useSelector((state: any) => state.app.eventId);

  useEffect(() => {
    if (query_params && query_params['whatsapp_redirect'] == 'true') {
      setTimeout(() => {
        const whatsapp = new WhatsappBuilder(
          {},
          asPath,
          referer,
          userId,
          eventId,
          CTATypes.MOENGAGE,
          locale
        );

        whatsapp.launchWhatsApp();
      }, 3000);
    }
  }, []);
  return;
};
export default useQueryRedirect;
