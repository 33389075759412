import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useContext
} from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useTranslation } from 'next-i18next';
import DropdownInput from './dropdown-input/DropdownInputContainer';
import Button from '../../../../new-components/ui/Button';
import SearchBar from '../../../search-new/components/search-bar/SearchBar';

export interface FilterProps {
  name: string;
  options: any[];
  selectedValues: number[];
}
export interface FiltersProps {
  property_types: FilterProps;
  bedrooms: FilterProps;
  beds_baths: FilterProps;
  price: FilterProps;
}

interface ChildProps {
  filters: FiltersProps;
  handleSearch: () => void;
  handlePriceRangeSelect: (minPrice: number, maxPrice: number) => void;
  handleSelect: (e: ChangeEvent<HTMLInputElement>, filterType: string) => void;
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<string>>;
  selectedFilters: string;
}
const SearchBarPresentation = ({
  filters,
  handleSelect,
  handlePriceRangeSelect,
  handleSearch,
  activeTab,
  setActiveTab,
  selectedFilters,
}: ChildProps) => {
  const { t } = useTranslation('common');
  const theme = useContext(ThemeContext);

  return (
    <Container itemScope itemType="http://schema.org/SearchAction">
      <div className="tab-container">
        <div
          className={`tab ${activeTab === 'compound' ? 'active' : ''}`}
          onClick={() => setActiveTab('compound')}
        >
          {t('compounds')}
        </div>
        <div
          className={`tab ${activeTab === 'property' ? 'active' : ''}`}
          onClick={() => setActiveTab('property')}
        >
          {t('properties')}
        </div>
      </div>
      <div className="search-input">
        <SearchBar
          selectedFilters={selectedFilters}
        />
      </div>
      <div className="dropdowns-container">
        <DropdownInput
          filter={filters.property_types}
          handleSelect={handleSelect}
        />
        <DropdownInput
          filter={filters.beds_baths}
          handleSelect={handleSelect}
        />
        <DropdownInput
          filter={filters.price}
          handleSelect={handleSelect}
          handlePriceRangeSelect={handlePriceRangeSelect}
        />
        <Button
          backgroundColor={theme.light.colors.primaryBlue}
          border="none"
          color="white"
          fontSize={theme.font.sizes.m}
          onClick={handleSearch}
          text={t('search')}
          backgroundHover={theme.light.hoverColors.primaryBlue}
          containerClass="search-btn"
        />
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04),
    0px 8px 16px 0px rgba(0, 0, 0, 0.08);

  .tab-container {
    display: flex;
    height: 40px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    width: 100%;
    .tab {
      padding: 10px 20px;
      cursor: pointer;
      border-bottom: 3px solid #d3dbe6;
      transition: border-bottom 0.3s ease;
      width: 100%;
      text-align: center;
      color: #5b6573;
      font-family: materialMedium;
      font-size: 14px;
    }

    .tab.active {
      border-bottom: 3px solid #76ade1;
      color: #1a2028;
      font-family: materialSemiBold;
      font-size: 14px;
    }
  }
  .search-input {
    width: 100%;
    flex: 1;
    min-height: 55px;
    display: flex;
    align-items: center;
    border: 1px solid #b0b0b0;
    border-radius: ${props => props.theme.borderRadius};

    .closed-input,
    .opened-input {
      outline: none;
      border: none;

      > svg {
        width: 20px;
        height: 20px;
      }
      .placeholder {
        color: ${props => props.theme.light.colors.darkGrey};
        font-size: ${props => props.theme.font.sizes.l};
      }

      input {
        padding: 8px 16px;
        font-family: materialMedium;
      }
    }
    .container-fluid {
      width: 100%;
      .search-dropdown {
        top: 60px;
      }
    }
  }

  .dropdowns-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 8px;
    margin-bottom: 16px;
  }
  .search-btn {
    height: 55px;
    width: 100%;
    max-width: 200px;

    button {
      width: 100%;
      height: 100%;
      font-family: materialRegular;
      border-radius: 12px;
      background: #015c9a;
      color: #eeeef0;
      text-align: center;
      font-size: 16px;
    }
  }
`;

export default SearchBarPresentation;
