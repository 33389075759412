import styled from 'styled-components';
import router, { useRouter } from 'next/router';
import { logEvent } from '../../../../new-lib/analytics';
import ImageWrapper from '../../../../new-components/ImageWrapper';

const OffersBannerMobile = (): JSX.Element => {
  const { locale } = useRouter();

  const bannerClickHandler = () => {
    router.push('/cityscape');
    logEvent.click('click_offers_banner', 'mobile_offers_banner', 'offers');
  };

  return (
    <Container
      onClick={bannerClickHandler}
      onLoad={() => router.prefetch('/cityscape')}
      itemScope
      itemType="http://schema.org/OfferCatalog"
    >
      <ImageWrapper
        src={`/assets/images/offers/offers-mobile-${locale}.svg`}
        alt="Offers content"
        width={160}
        height={114}
        priority
      />
      <ImageWrapper
        src="/assets/images/offers/percent.svg"
        alt="percent"
        width={30}
        height={28}
        priority
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  height: 100%;
  position: relative;
  border-radius: 8px;
  width: 280px;
  background: url('/assets/images/banners/offers-bg-mobile.webp') no-repeat
    center center;
  background-size: cover;
`;
export default OffersBannerMobile;
