import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import SearchIcon from '../../../../new-components/svg-icons/SearchIcon';
import { logEvent } from '../../../../new-lib/analytics';
import { useContext } from 'react';
import {
  SearchContext,
  SearchContextInterface
} from '../../../search-new/context/SearchContext';
import Background from '../../../../public/assets/images/home-images/landing-new-background.webp';
import Image from 'next/image';
import FilterIcon from '../../../../new-components/svg-icons/FilterIcon';
import { useRouter } from 'next/router';

const MobileLandingView = () => {
  const { t } = useTranslation('home');
  const { setShowFilters, searching, setShowUniversalSearch, setSearching } =
    useContext(SearchContext) as SearchContextInterface;
  const { locale } = useRouter();
  return (
    <MobileViewContainer
      className="container-fluid"
      itemScope
      itemType="https://schema.org/CreativeWork"
      locale={locale}
    >
      <Image
        src={Background}
        alt="hero background image"
        fill
        style={{
          objectFit: 'cover',
          overflow: 'hidden'
        }}
        priority
        placeholder="blur"
        quality={50}
        itemProp="image"
      />
      <div className="overlay"></div>
      <h1 className="heading" itemProp="text">
        <span>{t('landing_title')}</span>
        <span>{t('landing_sub_header')}</span>
      </h1>
      <div className="search-filter-bar">
        <MobileSearch
          onClick={() => {
            logEvent.click('search_click', 'SearchBar', 'search');
            setSearching(true);
            setTimeout(() => setShowUniversalSearch(true), 0);
          }}
        >
          <SearchIcon fill={'#788496'} />
          {searching ? '' : <span>{t('search_by_entity')}</span>}
        </MobileSearch>

        <div className="filter-icon" onClick={() => setShowFilters(true)}>
          <FilterIcon />
        </div>
      </div>
    </MobileViewContainer>
  );
};

const MobileViewContainer = styled.div<any>`
  position: relative;
  width: 100%;
  height: 293px;
  padding: 16px 16px 0px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  gap: 26px;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.27) 40%,
      rgba(0, 0, 0, 0.5) 100%
    );
    z-index: 0;
    pointer-events: none;
  }

  .search-filter-bar {
    display: flex;
    justify-content: center;
    gap: 4px;
    height: 50px;
    width: 100%;
    ${props => (props.locale === 'ar' ? 'margin-top: 20px;' : '')}

    .filter-icon {
      min-width: 50px;
      height: 50px;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: relative;
      border-radius: 12px;
      border: 1px solid #c6d0de;
      background: #f7f9fd;
    }
  }

  h1 {
    color: ${props => props.theme.light.colors.white};
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    z-index: 99;

    span:first-child {
      color: #eeeef0;
      text-align: center;
      font-family: materialSemiBold;
      font-size: 33px;
      text-transform: uppercase;
      line-height: 50px;
    }
    span:last-child {
      color: #eeeef0;
      text-align: center;
      font-family: materialRegular;
      font-size: 14px;
      line-height: 21px;
    }
  }
`;
const MobileSearch = styled.button`
  width: 100%;
  padding: 8px;
  border: none;
  border-radius: 12px;
  border: 1px solid #c6d0de;
  background: #fbfcfe;
  display: flex;
  align-items: center;
  position: relative;
  /* margin-top: 8px; */
  font-family: materialLight;
  font-size: ${props => props.theme.font.sizes.s};
  color: ${props => props.theme.light.colors.darkGrey};
  gap: 4px;
  height: 50px;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-shadow: 0px 12px 15px rgba(0, 0, 0, 0.25);
  overflow: hidden;

  span {
    color: #5b6573;
    font-size: 14px;
  }
  svg {
    min-width: 20px;
    height: 20px;
    path {
      fill: ${props => props.theme.light.colors.darkGrey};
    }
  }
`;

export default MobileLandingView;
