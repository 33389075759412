import styled from 'styled-components';
import ImageWrapper from '../../../../new-components/ImageWrapper';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { getImagePath } from '../../../../helpers/common';
import { logEvent } from '../../../../new-lib/analytics';

const Pathways = (props: any) => {
  const { t } = useTranslation('home');
  const handlePathwayClick = (pathway: string) => {
    logEvent.click(`pathway_click`, 'homepage', 'pathways', {
      pathway_text: `${pathway}`
    });
  };

  return (
    <Container>
      <h2>{t('what_are_you_looking_for')}</h2>
      <div className="paths-container">
        {props.pathways.map((path: any) => (
          <Link
            className="pathway"
            href={path?.link}
            key={path?.id}
            onClick={() => handlePathwayClick(path?.text)}
          >
            <div className="pathway-icon">
              <ImageWrapper
                src={getImagePath(path.image.url)}
                alt={`${path?.text} path icon`}
                width={44}
                height={40}
                style={{ objectFit: 'cover' }}
              />
            </div>
            <p>{path?.text}</p>
          </Link>
        ))}
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  h2 {
    color: #1e4164;
    font-family: materialBold;
    font-size: 24px;
    text-transform: capitalize;
  }
  .paths-container {
    display: flex;
    justify-content: space-between;
    gap: 16px;

    .pathway {
      width: 15%;
      height: auto;
      display: flex;
      padding: 16px 0px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 16px;
      border: 1px solid #d3dbe6;
      background: #fff;

      p {
        color: #1a2028;
        font-family: materialMedium;
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: ${props =>
      props.theme.breakPoints.mobile.max}) {
    overflow-x: hidden;

    h2 {
      font-size: 16px;
    }
    .paths-container {
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      display: flex;
      padding: 0px;
      align-items: flex-start;
      gap: 10px;

      .pathway {
        min-width: 33%;
        border: 0px solid transparent;
        -webkit-tap-highlight-color: transparent !important;

        .pathway-icon {
          display: flex;
          width: 110px;
          height: 64px;
          background: #f7f9fd;
          padding: 0px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 8px;
        }
        p {
          text-align: center;
        }
      }
    }
  }
`;

export default Pathways;
