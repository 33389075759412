import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';

interface CardProps {
  backgroundColor: string;
  cardTitle: string;
  cardDescription: string;
  titleColor: string;
  img: string;
  onChange: () => void;
  linkTo: string;
  dataTest: string;
}

const ServiceCard = ({
  backgroundColor,
  cardTitle,
  cardDescription,
  titleColor,
  img,
  linkTo,
  onChange,
  dataTest
}: CardProps) => {
  const { t } = useTranslation('home');
  const { locale } = useRouter();

  return (
    <Link href={linkTo} prefetch={false} legacyBehavior>
      <Container
        background={backgroundColor}
        titleColor={titleColor}
        onClick={onChange}
        locale={locale}
        data-test={dataTest}
      >
        <div className="card-content">
          <div className="title">{t(cardTitle)}</div>
          <div className="card-footer">
            <p>{t(cardDescription)}</p>
            <span>{t('learn_more')}</span>
          </div>
        </div>
        <div className="image-wrapper">
          <Image src={img} alt={cardTitle} fill quality={50} sizes="50vw" />
        </div>
      </Container>
    </Link>
  );
};
const Container = styled.div<any>`
  flex: 1;
  display: flex;
  align-items: center;
  height: 130px;
  background-color: ${props => props.background};
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  img {
    position: absolute !important;
    inset-inline-end: 0;
    transform: ${props => (props.locale == 'ar' ? ' scaleX(-1)' : 'unset')};
    top: 0 !important;
    border-top-right-radius: 8px;
  }
  .image-wrapper {
    position: absolute !important;
    width: 113px;
    height: 92px;
    top: 0 !important;
    inset-inline-end: 0;
  }
  .card-content {
    padding-inline: 34px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    .title {
      font-family: materialBold;
      font-size: 24px;
      color: ${props => props.titleColor};
    }
    .card-footer {
      display: flex;
      width: 100%;
      align-items: flex-end;
      justify-content: space-between;
      p {
        font-family: materialLight;
        font-size: ${props => props.theme.font.sizes.l};
        color: ${props => props.theme.light.colors.primaryBlue};
      }
      span {
        font-family: materialRegular;
        font-size: ${props => props.theme.font.sizes.l};
        color: ${props => props.theme.light.colors.primaryBlue};
        border-bottom: 1px solid
          ${props => props.theme.light.colors.primaryBlue};
        padding-bottom: 0px;
      }
    }
  }
  @media screen and (max-width: ${props =>
      props.theme.breakPoints.tablet.max}) {
    height: auto;
    .card-content {
      padding-inline: 18px;
      padding-top: 18px;
      padding-bottom: 18px;
      .card-footer {
        p {
          font-size: ${props => props.theme.font.sizes.s};
          width: 60%;
        }
        span {
          font-size: ${props => props.theme.font.sizes.xxs};
          transform: translateY(-35%);
        }
      }
    }
  }
`;

export default ServiceCard;
