import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';

// Components
import Slider from '../../../../new-components/Slider';
import DynamicCard from '../../../../new-components/cards/DynamicCard';
import { handleCardClickEvent } from '../../../../helpers/cards';

// Helpers
import { getCompressedImage, getImagePath } from '../../../../helpers/common';

const TopCompounds = (props: any) => {
  const isMobile = useSelector((state: any) => state.app.isMobile);
  const { t } = useTranslation('home');
  const getItemProps = (item: any, index: number) => {
    return {
      index: index,
      width: '100%',
      height: '100%',
      image: item?.cover_image?.url
        ? getImagePath(item?.cover_image?.url)
        : getCompressedImage(
            getImagePath(item.compound_images[0].image_path),
            true
          ),
      imageLayerData: {
        header: item.name,
        subHeader:
          item.properties_count + ' ' + t('properties', { ns: 'common' }),
        id: item.id
      },
      borderradius: '8px',
      linkTo: `/compound/${item.slug}`,
      onClick: () => {
        handleCardClickEvent({
          compound: item,
          eventAction: 'cmpnd_click',
          eventLocation: 'PopularCompoundsSection',
          index: index,
          recommended: false,
          featureName: 'compound'
        });
      }
    };
  };

  const getSliderChildren = (items: any) => {
    return items.map((item: any, index: number) => {
      const itemProps = getItemProps(item, index);
      return <DynamicCard key={item.id} {...itemProps} />;
    });
  };

  const popularCompoundsSliderProps = {
    width: '216px',
    height: '200px',
    slidesPerView: 'auto' as 'auto',
    spaceBetweenSlides: 0,
    slidesPerGroup: 1,
    children: getSliderChildren(props.popularCompounds)
  };

  return (
    <GridCardContainer isMobile={isMobile} data-test="top-compounds-section" itemScope itemType="http://schema.org/ItemList">
      <div>
        <h2 className="headline-title">{t('top_compounds')}</h2>
        {!isMobile && (
          <p className="headline-subtitle" data-test="top-compounds-subtitle" itemProp='numberOfItems'>
            {props.popularCompounds.length} {t('results_available')}
          </p>
        )}
      </div>
      <Slider {...popularCompoundsSliderProps} />
    </GridCardContainer>
  );
};

const GridCardContainer = styled.div<any>`
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 12px;
  h2 {
    color: ${props => props.theme.light.colors.secondaryBlue};
    font-size: 24px;
    font-family: materialBold;
  }
  p {
    color: ${props => props.theme.light.colors.black};
    font-size: ${props => props.theme.font.sizes.l};
    font-family: materialLight;
    margin-top: 4px;
  }
  @media screen and (max-width: ${props =>
      props.theme.breakPoints.tablet.max}) {
    gap: 8px;
    h2 {
      font-size: ${props => props.theme.font.sizes.l};
    }
  }
`;

export default TopCompounds;
