import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import styled from 'styled-components';

// Components
import Map from '../../../../new-components/map/Map';
import Modal from '../../../../new-components/mobile/Modal';
import Slider from '../../../../new-components/Slider';
import QuickView from '../../../../new-components/display-system/QuickView';
import RequestMeetingContainer from '../../../contact/components/request-meeting/RequestMeetingContainer';

import { getRecommendedProperties } from '../../../../new-lib/apis';
import GridCard from '../../../../new-components/cards/GridCard';
import { EntityTypes } from '../../../../new-lib/constants/common';

const RecomendedSection = () => {
  const { t } = useTranslation('home');
  const { locale } = useRouter();
  const isMobile = useSelector((state: any) => state.app.isMobile);
  const [mapData, setMapData] = useState<any>({});
  const [requestMeeting, setRequestMeeting] = useState<boolean>(false);
  const [recommendedProperties, setRecommendedProperties] = useState([]);

  useEffect(() => {
    async function fetchRecommendedProperties() {
      const recommendedProperties = await getRecommendedProperties(locale);
      setRecommendedProperties(recommendedProperties.general_properties);
    }
    fetchRecommendedProperties();
  }, []);

  const getSliderChildren = (items: any) => {
    return items.map((item: any, index: number) => {
      return (
        <GridCard
          entity={item}
          entityType={EntityTypes.PROPERTY}
          key={item.id}
          clickEventData={{
            property: item,
            eventAction: 'prop_click',
            eventLocation: 'RecommendedSection',
            index: index,
            compound: item.compound,
            area: item.area,
            recommended: true,
            featureName: 'property'
          }}
          recommendedClick={true}
          cardPosition={index}
        />
      );
    });
  };

  const recomendedSliderProps = {
    slidesPerView: 'auto' as 'auto',
    spaceBetweenSlides: !isMobile ? 16 : 8,
    slidesPerGroup: 1,
    children: getSliderChildren(recommendedProperties),
    width: !isMobile ? '445px' : '340px',
    height: '415px',
    autoPlay: true
  };

  const closeMap = () => {
    setMapData((prevState: any) => {
      return { ...prevState, showMap: false };
    });
  };
  return recommendedProperties.length > 0 ? (
    <Container isMobile={isMobile} data-test="recommended-section">
      <h2 className="headline-title">{t('Recommended')}</h2>
      {recommendedProperties.length > 0 && (
        <Slider {...recomendedSliderProps} />
      )}
      {isMobile && (
        <>
          <Modal
            closeFn={closeMap}
            isOpen={mapData.showMap}
            withCloseBtn={true}
            isMap={true}
          >
            <Map mapData={mapData.dimensions} name={mapData.name} />
          </Modal>

          <Modal
            closeFn={() => setRequestMeeting(false)}
            isOpen={requestMeeting}
            withCloseBtn={true}
          >
            <RequestMeetingContainer setFormOpen={setRequestMeeting} />
          </Modal>
        </>
      )}

      {!isMobile && (
        <>
          <QuickView isOpen={mapData.showMap} setIsOpen={closeMap} isMap={true}>
            <Map mapData={mapData.dimensions} name={mapData.name} />
          </QuickView>
          <QuickView
            isOpen={requestMeeting}
            setIsOpen={() => setRequestMeeting(false)}
          >
            <RequestMeetingContainer setFormOpen={setRequestMeeting} />
          </QuickView>
        </>
      )}
    </Container>
  ) : null;
};
const Container = styled.div<any>`
  height: auto;
  display: flex;
  flex-direction: column;
  h2 {
    font-family: materialBold;
    font-size: ${props =>
      props.isMobile ? props.theme.font.sizes.l : props.theme.font.sizes.xxxl};
    color: ${props => props.theme.light.colors.secondaryBlue};
  }
`;

export default RecomendedSection;
