import { useSelector } from 'react-redux';
import MobileLaunchesBanner from './mobile/LaunchesBanner';
import LaunchesBanner from './LaunchesBanner';
import { useRouter } from 'next/router';

interface LaunchesProps {
  launches: any;
}
const LaunchesBannerContainer = ({ launches }: LaunchesProps) => {
  const { asPath } = useRouter();
  const isMobile = useSelector((state: any) => state.app.isMobile);

  const renderBanner = () => {
    return isMobile || asPath.includes('/sahel-map') ? (
      <MobileLaunchesBanner launches={launches} />
    ) : (
      <LaunchesBanner launches={launches} />
    );
  };
  return launches && launches.length > 0 ? renderBanner() : null;
};

export default LaunchesBannerContainer;
