type PropsType = {
  stroke?: string;
  strokeWidth?: string;
  width?: string;
};

const RightIcon = ({ width, strokeWidth, stroke }: PropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? '6'}
      height="14"
      viewBox="0 0 8 14"
      fill="none"
    >
      <path
        d="M1 0.999999L7 7L1 13"
        stroke={stroke ? stroke : '#0C314E'}
        strokeWidth={strokeWidth ?? '1'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RightIcon;
