import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import useOnClickOutside from '../../hooks/ClickOutside';
import ButtonIcon from '../ui/ButtonIcon';
import CloseIcon from '../svg-icons/CloseIcon';

interface QuickViewProps {
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isMap?: boolean;
  width?: string;
}

const QuickView = ({
  children,
  isOpen,
  setIsOpen,
  isMap,
  width
}: QuickViewProps) => {
  const { locale } = useRouter();
  const viewRef = useRef(null);

  const closeView = () => {
    setIsOpen(false);
  };

  useOnClickOutside(viewRef, viewRef, closeView);

  useEffect(() => {
    const handleEscapeButtonClick = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && isOpen) {
        closeView();
      }
    };

    document.addEventListener('keydown', handleEscapeButtonClick);

    if (isOpen) {
      document.body.style.pointerEvents = 'none';
    } else {
      document.body.style.pointerEvents = 'all';
    }

    return () => {
      document.removeEventListener('keydown', handleEscapeButtonClick);
      document.body.style.pointerEvents = 'all';
    };
  }, [isOpen]);

  return (
    <CSSTransition in={isOpen} timeout={300} unmountOnExit classNames="view">
      <Container
        locale={locale}
        isMap={isMap}
        ref={viewRef}
        width={width}
        data-test="quick-view"
      >
        <div
          className="view-controllers"
          data-test="quick-view-close"
          onClick={closeView}
        >
          <ButtonIcon
            icon={<CloseIcon />}
            btnClass="close-btn"
            backgroundColor="#F3F3F3"
            border="none"
            color="none"
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </div>
        <div className="view-content">{children}</div>
      </Container>
    </CSSTransition>
  );
};

interface ContainerProps {
  locale?: string;
  isMap?: boolean;
  width?: string;
}

const Container = styled.div<ContainerProps>`
  pointer-events: all;
  position: fixed;
  top: 0px;
  inset-inline-end: 0px;
  width: ${props => (props.width ? props.width : '60%')};
  max-width: 1000px;
  height: 100%;
  background: #ffffff;
  box-shadow: ${props =>
    props.locale == 'en'
      ? '-50px 0px 70px 8px rgba(0, 0, 0, 0.2)'
      : '50px 0px 70px 8px rgba(0, 0, 0, 0.2)'};
  overflow: ${props => (props.isMap ? 'hidden' : 'auto')};
  overflow-x: hidden;
  z-index: 999999;
  ::-webkit-scrollbar {
    display: none;
  }
  // Enter animation
  &.view-enter {
    inset-inline-end: -52%;
  }

  &.view-enter-active {
    inset-inline-end: 0px;
    transition: inset-inline-end 300ms ease-out;
  }

  &.view-enter-done {
    inset-inline-end: 0px;
  }

  // Exit animation
  &.view-exit {
    inset-inline-end: 0px;
  }

  &.view-exit-active {
    inset-inline-end: -52%;
    transition: inset-inline-end 300ms ease-out;
  }

  &.view-exit-done {
    inset-inline-end: -52%;
  }

  .view-controllers {
    position: absolute;
    inset-inline-end: 25px;
    top: 25px;
    display: flex;
    align-items: center;
    z-index: 10;
    .expand-btn {
      svg {
        width: 35px;
        height: 35px;
      }
    }

    .close-btn {
      width: 35px;
      height: 35px;
      border-radius: 3px;
      margin-inline-start: 16px;

      svg {
        width: 16px;
        height: 19px;
      }
    }
  }
  .view-content {
    width: 100%;
    height: 100%;
  }

  .fslightbox-container {
    position: relative;
    width: 100%;
    background: transparent;
    box-shadow: none;
    z-index: 1;

    .fslightbox-source-inner {
      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }

    .fslightbox-toolbar {
      display: none;
    }

    .fslightbox-slide-number-container {
      margin-top: 25px;
      margin-inline-start: 25px;
      color: rgba(0, 0, 0, 0.8);
      .fslightbox-slash {
        background: rgba(0, 0, 0, 0.8);
      }
    }
  }
`;
export default QuickView;
