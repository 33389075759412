import React from 'react';
import styled from 'styled-components';
import { logEvent } from '../../../../../new-lib/analytics';
import Link from 'next/link';
import PrevArrowIcon from '../../../../../new-components/svg-icons/PrevArrowIcon';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { getPageLocation } from '../../../../../helpers/common';

interface LaunchesBannerHeaderContainerProps {
  locale?: string;
}

const LaunchesBannerHeader = () => {
  const { t } = useTranslation('launches');
  const { locale, asPath } = useRouter();

  const isMobile = useSelector((state: any) => state.app.isMobile);

  const handleExploreButtonClick = () => {
    logEvent.click('lnch_view_all', getPageLocation(asPath), 'launch');
  };

  return (
    <Container locale={locale}>
      <h2 className="headline-title">{t('new_launches')}</h2>

      <div className="explore-more">
        <Link
          className="explore-more-btn"
          href="/new-launches"
          onClick={handleExploreButtonClick}
          prefetch={false}
        >
          {t('show_all')} {!isMobile && <PrevArrowIcon stroke="#1E4164" />}
        </Link>
      </div>
    </Container>
  );
};

const Container = styled.div<LaunchesBannerHeaderContainerProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .explore-more {
    .explore-more-btn {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #1e4164;
      font-family: materialBold;
      font-size: 15px;
      text-transform: capitalize;

      @media screen and (max-width: ${props =>
          props.theme.breakPoints.mobile.max}) {
        color: #4676a7;
        text-decoration: underline;
        font-family: materialMedium;
      }
      svg {
        width: 6px;
        transform: ${props => (props.locale == 'en' ? 'rotate(180deg)' : '')};
      }
    }
  }
`;

export default LaunchesBannerHeader;
