import React, { ChangeEvent, useRef, useState } from 'react';
import { FilterProps } from '../SearchBarPresentation';
import useOnClickOutside from '../../../../../lib/Custom Hooks/ClickOutside';
import DropdownInputPresentation from './DropdownInputPresentation';
import { useTranslation } from 'next-i18next';

interface DropdownProps {
  filter: FilterProps;
  handleSelect: (e: ChangeEvent<HTMLInputElement>, filterType: string) => void;
  handlePriceRangeSelect?: (minPrice: number, maxPrice: number) => void;
}

const DropdownInput = ({
  filter,
  handleSelect,
  handlePriceRangeSelect
}: DropdownProps) => {
  const { t } = useTranslation('home');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, dropdownRef, () => {
    setTimeout(() => setDropdownOpen(false), 250);
  });

  const [priceRangeLabel, setPriceRangeLabel] = useState('');

  const formatPriceRangeLabel = (values: number[]) => {
    const minValue = values[0];
    const maxValue = values[1];
    const formatValue = (value: number) => {
      const millions = value / 1000000;
      return millions % 1 === 0
        ? `${millions}${t('m')}`
        : `${millions.toFixed(1)}${t('m')}`;
    };
    setPriceRangeLabel(
      `${formatValue(minValue)} ${t('to')} ${formatValue(maxValue)}`
    );
  };

  const [values, setValues] = useState([500000, 25000000]);

  const handleMinPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.target.value.replace(/,/g, ''));
    if (newValue > 25000000) {
      return;
    }
    if (!isNaN(newValue)) {
      setValues([newValue, values[1]]);
    }
  };

  const handleMaxPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.target.value.replace(/,/g, ''));
    if (newValue > 25000000) {
      return;
    }
    if (!isNaN(newValue)) {
      setValues([values[0], newValue]);
    }
  };

  const handleRangeChange = (newValues: number[]) => {
    setValues(newValues);
  };

  const updateFilter = () => {
    formatPriceRangeLabel(values);
    handlePriceRangeSelect && handlePriceRangeSelect(values[0], values[1]);
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <DropdownInputPresentation
      filter={filter}
      handleSelect={handleSelect}
      updateFilter={updateFilter}
      handleRangeChange={handleRangeChange}
      handleMaxPriceChange={handleMaxPriceChange}
      handleMinPriceChange={handleMinPriceChange}
      priceRangeLabel={priceRangeLabel}
      values={values}
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      dropdownRef={dropdownRef}
    />
  );
};

export default DropdownInput;
