import styled from 'styled-components';
import CloseIcon from '../svg-icons/CloseIcon';
import Link from 'next/link';
import ImageWrapper from '../ImageWrapper';

// Interfaces
interface DynamicCardInterface {
  index?: number;
  width: string;
  height: string;
  image: string;
  cardType?: string;
  background?: string;
  borderradius?: string;
  onClick?: () => void;
  linkTo: string;
  outerSection?: {
    header: string;
  };
  imageLayerData?: {
    header: string;
    subHeader: string;
  };
  enableClose?: boolean;
  onClickClose?: () => void;
}

const DynamicCard: React.FC<DynamicCardInterface> = props => {
  return (
    <Link href={props.linkTo} passHref={true} prefetch={false} legacyBehavior>
      <CardContainer
        borderradius={props.borderradius}
        background={props.background}
        onClick={props.onClick}
      >
        <CardImageContainer width={props.width} height={props.height}>
          {props.image && (
            <ImageWrapper
              src={props.image}
              alt={props.image}
              fill
              priority={props.index && props.index > 1 ? false : true}
              objectFit="cover"
              withSkeleton
              imageSize="low"
              sizes="50vw"
            />
          )}
          {props.imageLayerData && (
            <ImageLayer
              borderradius={props.borderradius}
              withlayer={props.imageLayerData ? 'true' : 'false'}
            >
              <ImageLayerContent className="image-layer-content">
                <span className="header">{props.imageLayerData.header}</span>
                <span className="subHeader">
                  {props.imageLayerData.subHeader}
                </span>
              </ImageLayerContent>
            </ImageLayer>
          )}
        </CardImageContainer>
        {props.outerSection?.header && (
          <CardOuterSection>
            <span>{props.outerSection?.header}</span>
          </CardOuterSection>
        )}
        {props.enableClose && (
          <div className="closeButt" onClick={props.onClickClose}>
            <CloseIcon
              styles={{ width: '9px', height: '11px' }}
              fill=" #FFFFFF"
            />
          </div>
        )}
      </CardContainer>
    </Link>
  );
};

const CardContainer = styled.a<any>`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  background-color: ${props => props.background};
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  border-radius: ${props => props.theme.borderRadius};
  font-family: materialMedium;
  cursor: pointer;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  background-color: ${props => props.theme.light.colors.lightGrey};
  margin-inline-end: 16px;
  .closeButt {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 27px;
    height: 27px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const CardImageContainer = styled.div<any>`
  width: ${props => props.width};
  height: ${props => props.height};
  position: relative;
`;
const ImageLayer = styled.div<any>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: ${props => props.theme.borderRadius};
  transition: all 1.3s ease-in-out;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 52.15%, #000000 87.66%);

  @media screen and (min-width: ${props =>
      props.theme.breakPoints.desktop.min}) {
    &:hover {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 42.22%,
        #152b42 87.66%
      );
      animation: change-gradient 0.1s ease-in-out;
    }
    &:hover .header {
      color: ${props => props.theme.light.colors.white};
    }
    &:hover .subHeader {
      color: ${props => props.theme.light.colors.white};
    }
    @keyframes change-gradient {
      0% {
        background-image: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 52.15%,
          #000000 87.66%
        );
      }
      100% {
        background-image: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 42.22%,
          #152b42 87.66%
        );
      }
    }
  }
`;
const ImageLayerContent = styled.div<any>`
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  z-index: 10;
  padding: 0px 8px;
  .header {
    transition: all 0.3s ease-in-out;
    transform-origin: center;
    font-family: materialBold;
    color: ${props => props.theme.light.colors.white};
    font-size: ${props => props.theme.font.sizes.l};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media screen and (max-width: ${props =>
        props.theme.breakPoints.tablet.max}) {
      font-size: ${props => props.theme.font.sizes.s};
    }
  }
  .subHeader {
    font-size: ${props => props.theme.font.sizes.s};
    @media screen and (max-width: ${props =>
        props.theme.breakPoints.tablet.max}) {
      font-size: ${props => props.theme.font.sizes.xxs};
    }
    color: #8e8e8e;
    font-family: materialMedium;
  }
`;
const CardOuterSection = styled.div<any>`
  font-size: ${props => props.theme.font.sizes.l};
  @media screen and (max-width: ${props =>
      props.theme.breakPoints.tablet.max}) {
    font-size: ${props => props.theme.font.sizes.s};
  }
  font-family: materialBold;
  text-align: center;
  text-transform: capitalize;
  color: ${props => props.theme.light.colors.secondaryBlue};
`;

export default DynamicCard;
