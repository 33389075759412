import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useContext
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import ThinArrowIcon from '../../../../../new-components/svg-icons/ThinArrowIcon';
import { FilterProps } from '../SearchBarPresentation';
import { logEvent } from '../../../../../new-lib/analytics';
import {
  SearchContext,
  SearchContextInterface
} from '../../../../search-new/context/SearchContext';
import { Range, getTrackBackground } from 'react-range';
import Button from '../../../../../new-components/ui/Button';
import { useRouter } from 'next/router';

interface DropdownProps {
  filter: FilterProps;
  handleSelect: (e: ChangeEvent<HTMLInputElement>, filterType: string) => void;
  handleRangeChange: (newValues: number[]) => void;
  handleMaxPriceChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleMinPriceChange: (e: ChangeEvent<HTMLInputElement>) => void;
  updateFilter: () => void;
  priceRangeLabel: string;
  values: number[];
  dropdownRef: React.RefObject<HTMLDivElement>;
  dropdownOpen: boolean;
  setDropdownOpen: Dispatch<SetStateAction<boolean>>;
}

const DropdownInputPresentation = ({
  filter,
  handleSelect,
  handleRangeChange,
  handleMaxPriceChange,
  handleMinPriceChange,
  updateFilter,
  priceRangeLabel,
  values,
  dropdownRef,
  dropdownOpen,
  setDropdownOpen
}: DropdownProps) => {
  const { t } = useTranslation('search');
  const { locale } = useRouter();
  const { searchFiltersState } = useContext(
    SearchContext
  ) as SearchContextInterface;

  //Placeholders to let slider move when value changes
  const MIN = 500000;
  const MAX = 25000000;

  const getPlaceholder = () => {
    let label: string = '';
    if (filter.selectedValues.length > 0) {
      switch (filter.name) {
        case 'property_types':
          label = filter.selectedValues
            .map((value: any) => value.name)
            .join(', ');
          break;

        case 'beds_baths':
          const bedrooms: any = filter.selectedValues.filter((item: any) =>
            item.name.includes(t('bedrooms'))
          );

          const bathrooms: any = filter.selectedValues.filter((item: any) =>
            item.name.includes(t('bathrooms'))
          );

          const bedroomText = bedrooms.length
            ? `${bedrooms
                .map((value: any) => value.value)
                .sort()
                .join(', ')} ${
                bedrooms.length == 1 && bedrooms[0]?.value == '1'
                  ? t('bedroom')
                  : t('bedrooms')
              }`
            : '';
          const bathroomText = bathrooms.length
            ? `${bathrooms
                .map((value: any) => value.value)
                .sort()
                .join(', ')} ${
                bathrooms.length == 1 && bathrooms[0]?.value == '1'
                  ? t('bathroom')
                  : t('bathrooms')
              }`
            : '';

          label = [bedroomText, bathroomText].filter(Boolean).join(' - ');
          break;

        case 'price_range':
          label = priceRangeLabel;
          break;
      }

      return (
        <span data-test="selected-search-filter" className="selected">
          {label}
        </span>
      );
    } else {
      return (
        <span data-test="unselected-search-filter"> {t(filter.name)}</span>
      );
    }
  };

  const renderBedroomsAndBathrooms = () => (
    <div className="dropdown">
      <h3>{t('bedrooms')}</h3>
      <div className="filter-options">
        {filter.options
          .slice(0, 5)
          .map((item: any, index: number) =>
            renderOption('bedrooms', item, index)
          )}
      </div>

      <div className="separator" />

      <h3>{t('bathrooms')}</h3>
      <div className="filter-options">
        {filter.options
          .slice(5, 10)
          .map((item: any, index: number) =>
            renderOption('bathrooms', item, index)
          )}
      </div>
    </div>
  );

  const renderOption = (type: string, item: any, index: number) => {
    return (
      <label
        className={`option ${
          filter.selectedValues.some((value: any) => value.name === item.name)
            ? 'selected'
            : ''
        }`}
        data-test="dropdown-menu-option"
        key={item.value}
      >
        <input
          type="checkbox"
          name={
            // type === 'bathrooms'
            //   ? `${index + 1 == 6 ? '6+' : index + 1} ${t(type)}` :
            item.name
          }
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleSelect(e, type);
          }}
          value={item.value}
        />
        {item.value}
      </label>
    );
  };

  const renderPriceRange = () => (
    <div className="price-range-dropdown" onClick={e => e.stopPropagation()}>
      <h3>{t('price_range')}</h3>
      <div className="price-range-input">
        <input
          type="text"
          value={values[0].toLocaleString()}
          onChange={handleMinPriceChange}
          placeholder="Min Price"
        />
        <input
          type="text"
          value={values[1].toLocaleString()}
          onChange={handleMaxPriceChange}
          placeholder="Max Price"
        />
      </div>
      <div className="range-bar">
        <Range
          values={values}
          step={500000}
          min={MIN}
          max={MAX}
          onChange={handleRangeChange}
          rtl={locale == 'ar'}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '10px',
                width: '100%',
                background: getTrackBackground({
                  values,
                  colors: ['#275885', '#18436b'],
                  min: MIN,
                  max: MAX
                })
              }}
              className="range-bar-bg"
            >
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div {...props}>
              <div />
            </div>
          )}
        />
      </div>
      <Button
        backgroundColor={'#015C9A'}
        border="none"
        color="white"
        fontSize={'14'}
        onClick={updateFilter}
        text={t('apply')}
        containerClass="apply-btn"
      />
    </div>
  );

  const renderDefaultOptions = () => (
    <div>
      {filter.options.map((item: any) => (
        <label
          className="option property-type"
          data-test="dropdown-menu-option"
          key={item.value}
        >
          <input
            type="checkbox"
            name={item.name}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleSelect(e, filter.name);
            }}
            value={item.value}
          />
          {item.name}
        </label>
      ))}
    </div>
  );

  return (
    <Container
      data-test="search-filter-btn"
      ref={dropdownRef}
      onClick={() => {
        setDropdownOpen(!dropdownOpen);
        logEvent.search(
          `filter_${filter.name}`,
          'SearchBar',
          'filter',
          searchFiltersState
        );
      }}
    >
      {getPlaceholder()}

      <ThinArrowIcon />

      <Dropdown className={dropdownOpen ? 'hide-scrollbar' : 'closed'}>
        <div className="options-wrapper" data-test="options-dropdown-menu">
          {filter.name === 'beds_baths'
            ? renderBedroomsAndBathrooms()
            : filter.name === 'price_range'
            ? renderPriceRange()
            : renderDefaultOptions()}
        </div>
      </Dropdown>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  background-color: white;
  border: 1px solid #b0b0b0;
  padding: 16px 16px;
  border-radius: ${props => props.theme.borderRadius};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  min-width: 160px;
  height: 55px;
  gap: 4px;
  span {
    color: ${props => props.theme.light.colors.darkGrey};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &.selected {
      color: ${props => props.theme.light.colors.black};
    }
  }

  svg {
    transform: rotate(270deg);
    min-width: 8px;
    height: 12px;
    path {
      stroke: ${props => props.theme.light.colors.grey};
    }
  }
`;

const Dropdown = styled.div`
  position: absolute;
  inset-inline-start: 0;
  max-height: 300px;
  top: 60px;
  background-color: white;
  width: 100%;
  overflow: auto;
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  border-radius: 12px;
  border: 1px solid #d3dbe6;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04),
    0px 8px 16px 0px rgba(0, 0, 0, 0.08);

  &.closed {
    display: none;
  }

  .options-wrapper {
    display: flex;
    flex-direction: column;
    height: max-content;
    width: 100%;

    .filter-options {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      label {
        display: flex;
        width: 30px;
        height: 30px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px solid #d3dbe6;
        background: #fff;

        &.selected {
          color: white;
          background-color: #015c9a;
        }
      }
      input[type='checkbox'] {
        display: none;
      }
    }

    .separator {
      height: 0.5px;
      background-color: #d3dbe6;
      margin: 16px 0px;
    }

    .filter-option {
      display: flex;
      width: 30px;
      height: 30px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid #d3dbe6;
      background: #fff;
    }
  }

  .price-range-dropdown {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .apply-btn {
      width: 100%;
      padding: 8px 0px;
      button {
        height: 40px;
        width: 100%;
      }
    }

    .price-range-input {
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: 8px;

      input {
        text-align: center;
        width: 50% !important;
        padding: 8px !important;
        border-radius: 12px;
        border: 1px solid #c6d0de;
        color: #5b6573;
        font-family: materialRegular;
        font-size: 14px;
      }
    }
    .range-bar {
      display: flex;
      align-self: center;
      flex-wrap: wrap;
      width: 90%;
      margin: 16px 0px;
      div[role='slider'] {
        background-color: #015c9a !important;
        width: 24px;
        height: 24px;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        border-radius: 50%;
        div {
          background-color: #fbfdff !important;
          border-radius: 50% !important;
          width: 12px !important;
          height: 12px !important;
          margin: 0 auto;
        }
      }
      .range-bar-bg {
        background-color: #e8f4ff;
        border-radius: 360px;
      }
    }
  }

  h3 {
    color: #1a2028;
    font-family: materialBold;
    font-size: 17px;
    margin-bottom: 8px;
  }

  .option {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 8px 0px;
    height: 48px;
    font-size: ${props => props.theme.font.sizes.s};
    font-family: materialMedium;
    color: ${props => props.theme.light.colors.black};
    cursor: pointer;
    line-height: 18px;

    &.property-type {
      border-bottom: 1px solid #d3dbe6;
      :last-child {
        border-bottom: 1px solid transparent;
      }
    }

    input[type='checkbox'] {
      pointer-events: none;
      cursor: pointer;
      -webkit-appearance: none;
      appearance: none;
      background-color: #fff;
      margin: 0;
      display: grid;
      place-content: center;
      font: inherit;
      width: 20px;
      height: 20px;
      border: 1px solid #d0d5dd;
      border-radius: 6px;
      color: #006694;

      &::before {
        content: '';
        width: 0.65em;
        height: 0.65em;
        transform: scale(0);
        transition: 0.1s transform ease-in-out;
        box-shadow: inset 15px 15px #006694;
        transform-origin: bottom left;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      }

      &:checked {
        border: 1px solid #006694;
        background-color: #e6f0f4;

        &::before {
          transform: scale(1);
        }
      }
    }
  }
`;

export default DropdownInputPresentation;
